import { useLocation } from '@remix-run/react';
import { ID } from '@segment/analytics-next';
import React from 'react';
import { redditPixelRoutes } from '~/constants/reddit';
import { PageRoutes } from '~/constants/routes';

export default function RedditPixel({ anonymousId, email }: { anonymousId: ID; email?: string }) {
  const location = useLocation();
  const shouldUsePixel = redditPixelRoutes.includes(location.pathname as PageRoutes);

  React.useEffect(() => {
    if (anonymousId && shouldUsePixel && !document.getElementById('reddit-pixel')) {
      const script = document.createElement('script');
      script.id = 'reddit-pixel';
      script.innerHTML = `
        !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);
        rdt('init', 'a2_frxlr5tvqeih', { email: '${email || ''}', externalId: '${anonymousId || ''}' });
        rdt('track', 'PageVisit');
      `;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [anonymousId, email, shouldUsePixel]);

  return null;
}
