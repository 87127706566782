import React from 'react';
import { analytics } from './useLoadSegment';
import { useLocation } from '@remix-run/react';
import { PageRoutes } from '~/constants/routes';

const pageNameMap: { [key: string]: string } = {
  [PageRoutes.LANDING_PAGE]: 'Landing',
  [PageRoutes.TEMPLATES]: 'Schedule template select',
  [PageRoutes.PARENT_INFO]: 'Parent info',
  [PageRoutes.SCHEDULE_DETAILS]: 'Schedule info',
  [PageRoutes.CALENDAR_PREVIEW]: 'Calendar view',
  [PageRoutes.HOLIDAYS_LIST]: 'Holiday list',
  [PageRoutes.HOLIDAYS_ADD]: 'Add a holiday',
};

const holidaysRouteRegex = /^\/holidays\/edit\/\d+$/;
function getPageName(pathname: string) {
  if (pageNameMap[pathname]) return pageNameMap[pathname];

  const dynamicRoutes = [{ pattern: holidaysRouteRegex, name: 'Edit a holiday' }];

  for (const route of dynamicRoutes) {
    if (route.pattern.test(pathname)) return route.name;
  }

  return '';
}

export default function useSegmentPageView() {
  const location = useLocation();
  React.useEffect(() => {
    analytics.page(undefined, getPageName(location.pathname));
  }, [location.pathname]);
}
