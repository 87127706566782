import React from 'react';
import { analytics } from './useLoadSegment';
import { ID } from '@segment/analytics-next';

export default function useSetAnonymousId() {
  const [id, setId] = React.useState<ID>(undefined);

  React.useEffect(() => {
    const setAnonymousId = async () => {
      const anonymousId = (await analytics.user()).anonymousId();

      try {
        await fetch('/api-set-anonymous-id', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ anonymousId }),
        });
        setId(anonymousId);
      } catch (error) {
        console.log('Error /api-set-anonymous-id ', error);
      }
    };

    setAnonymousId();
  }, []);

  return id;
}
