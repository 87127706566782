import { cssBundleHref } from '@remix-run/css-bundle';
import type { LinksFunction, LoaderFunctionArgs } from '@remix-run/node';
import stylesheet from '~/css/tailwind.css?url';
import landingImgSmall from './assets/landing-small-4x.webp';
import dropdownMenuStylesheet from '~/css/dropdownMenuStyles.css?url';
import { Links, Meta, Outlet, Scripts, ScrollRestoration, json, useLoaderData, useLocation } from '@remix-run/react';
import ProgressBar from './components/progressBar/ProgressBar';
import React from 'react';
import { Toaster } from './components/ui/toaster';
import { getEnvironment } from './utils/getEnvironment';
import { segmentWriteKeys } from './constants/segment';
import useLoadSegment from './analytics/useLoadSegment';
import useSegmentPageView from './analytics/useSegmentPageView';
import useSetAnonymousId from './analytics/useSetAnonymousId';
import RedditPixel from './components/redditPixel/RedditPixel';
import { redditPixelRoutes } from './constants/reddit';
import { PageRoutes } from './constants/routes';
import { getSession, logout } from './sessions';
import { TOKEN } from './constants/cookies';
import { getUserProfile } from './utils/getUserProfile';

export const links: LinksFunction = () => [
  ...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : []),
  { rel: 'stylesheet', href: stylesheet },
  { rel: 'stylesheet', href: dropdownMenuStylesheet },
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  { rel: 'preconnect', href: 'https://fonts.gstatic.com' },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap',
  },
  {
    rel: 'preload',
    href: landingImgSmall,
    as: 'image',
  },
];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const environment = getEnvironment(url.hostname);
  const segmentWriteKey = segmentWriteKeys[environment];
  const session = await getSession(request);
  const token = session.get(TOKEN) as string;

  try {
    if (token) {
      const response = await getUserProfile(token);
      if (response) return json({ email: response.email, segmentWriteKey });
      return logout({ session });
    }
  } catch (error) {
    return logout({ session });
  }

  return json({ email: '', segmentWriteKey });
};

export function Layout({ children }: { children: React.ReactNode }) {
  const { email, segmentWriteKey } = useLoaderData<typeof loader>();
  const location = useLocation();
  useLoadSegment({ writeKey: segmentWriteKey });
  useSegmentPageView();
  const anonymousId = useSetAnonymousId();
  const isRedditPixelRoute = redditPixelRoutes.includes(location.pathname as PageRoutes);

  return (
    <html lang="en">
      <head>
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <style data-fullcalendar />
        <Links />
      </head>
      <body id="app">
        {children}
        <ScrollRestoration />
        <Scripts />
        <ProgressBar />
        <Toaster />
        {isRedditPixelRoute ? <RedditPixel anonymousId={anonymousId} email={email} /> : null}
      </body>
    </html>
  );
}

export default function App() {
  return <Outlet />;
}
