import { useNavigation } from '@remix-run/react';
import React from 'react';

const ProgressBar: React.FC = () => {
  const navigation = useNavigation();

  return (
    <div
      className={`fixed left-0 top-0 h-1 w-full transition-all ${
        navigation.state === 'loading' ? 'bg-green animate-progress' : 'w-0 bg-transparent'
      }`}
    />
  );
};

export default ProgressBar;
